import React, {useState, useEffect, useRef} from "react";
import { Helmet } from 'react-helmet';

//style
import "./baseline_scan.css";
//components
import VideoCamera, {CAM_TYPE_BASELINE} from "../../components/scanner/camera/videoCamera";
import VideoStreamSocket from "../../components/scanner/socket/socket";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
import Lobby from "../../components/scanner/foreplay/lobby";
import ScanStep from "../../components/scanner/foreplay/obi_one";
import CamSettingsLoader from "../../components/scanner/foreplay/load_cam_settings";
import Notification from "../../components/side_notification/side_notification";
import Btn from "../../components/buttons/standard/btn";
//containers
//utils
import { doesHaveCamProblem, isIOS, postReqOptBuilder } from "../../utils/main_utils";
import { blobToFile, fetchPresignedUrls, getFileExtFromBlob, uploadFile } from "../../utils/vid_upload_utils";
//assets
import step1x1 from "../../assets/scan_page/step1.jpg"
import step1x2 from "../../assets/scan_page/step1@2x.jpg"
import step1x3 from "../../assets/scan_page/step1@3x.jpg"
import MainLoader from "../../components/loaders/main_loader/main_loader";
import ScannerError from "../../components/errorMessages/scannerError/scannerError";
//constants
const SOCKET_NS = "test_socket";
const FPS = 20;
const CAMERA_CONSTRAINTS = {
    video: {
        facingMode: { exact: 'environment' },
        frameRate: { ideal: FPS },
        aspectRatio: 16/9,  // 1.777777778,
        zoom: 1,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        // ...(isIOS() ? {
        //     width: { ideal: 1920 },
        //     height: { ideal: 1080 },
        // } : {})
    },
    audio: false
};
const STEP_LOBBY = 0;
const STEP_EXP = 1;
const STEP_EXP2 = 2;
const STEP_SCAN = 3;
const STEP_DONE = 4;
const STEP_RETRY_FILE_UPLOAD = 9;
const STEP_LOADING_ISSUE = 10;
const STEP_LOADING_ISSUE_RESOLVED = 11;
const STEP_UPLOAD_FAILED = 12;

export default function BaselineScan (props) {
    const pid = props.match.params.pid;
    const uid = props.uid_cid['uid'];
    const cid = props.uid_cid['cid'];
    const token = props.token;
    const base_url = props.base_url;
    const debugMode = (new URLSearchParams(window.location.search)).get('debug') === '1';
    // state based variables
    const [loadingPage, setLoadingPage] = useState(true);
    const [socket, setSocket] = useState(null);
    const [sockets, setSockets] = useState(null);
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [finalizingScan, setFinalizingScan] = useState(false);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const [videoDeviceDets, setVideoDeviceDets] = useState(null);
    const [step, setStep] = useState(STEP_LOBBY);
    const [notifState, setNotifState] = useState(null);
    const [shouldCompress, setShouldCompress] = useState(null);
    const [sockConnStatusState, setSockConnStatusState] = useState({});
    const [failedApiCalls, setFailedApiCalls] = useState(0);
    const [lastMass, setLastMass] = useState(null);
    const [remainingFrames, setRemainingFrames] = useState(0);
    const [fileUploadTimer, setFileUploadTimer] = useState(null);
    // video upload related
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [recordingUploadRate, setRecordingUploadRate] = useState(0);
    const [uploadLoaderStatus, setUploadLoaderStatus] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    // general variables
    let scanSess = useRef(null);
    let scannedFrames = useRef(0);
    let framesSent = useRef(0);
    let lp_frameIdx = useRef(0);
    let ignoredFrames = useRef(0);
    let socketDisconnections = useRef(0);
    let finalizeBsInterval = useRef(null);
    let framesCache = useRef({});
    let lastResend = useRef(null);
    let lastReceive = useRef(null);
    let midScanDisconnection = useRef(false);
    let isDoneUploadingInterval = useRef(null);
    let lastDisconnect = useRef(null);
    let disconnectCounter = useRef(0);
    let sockConnReset = useRef(0);

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const switchStep = (next_step) => {
        let permitSwitch = false;
        if (next_step === STEP_LOADING_ISSUE) {
            if (finalizingScan && step !== STEP_DONE) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_RETRY_FILE_UPLOAD) {
            if (step === STEP_LOADING_ISSUE || step === STEP_UPLOAD_FAILED) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_UPLOAD_FAILED) {
            if (step === STEP_LOADING_ISSUE || step === STEP_RETRY_FILE_UPLOAD) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_LOADING_ISSUE_RESOLVED) {
            if (step === STEP_LOADING_ISSUE) {
                permitSwitch = true;
            }
        } else {
            permitSwitch = true;
        }
        if (permitSwitch) {
            setStep(next_step);
        } else {
            console.log(`Switch step from ${step} to ${next_step} is not permitted`);
        }
    };

    const getFinalConstraints = () => {
        if (videoDeviceDets !== true && videoDeviceDets !== false) {
            return {
                video: {
                    ...CAMERA_CONSTRAINTS.video,
                    deviceId: videoDeviceDets.deviceId
                },
                audio: CAMERA_CONSTRAINTS.audio
            }
        } else return CAMERA_CONSTRAINTS
    }

    const switchToLoading = (sid, totalFrames, sentFrames, corrupted_blobs) => {
        if (!finalizingScan) setFinalizingScan(true);
        framesSent.current = sentFrames;
        scannedFrames.current = totalFrames;
        ignoredFrames.current = corrupted_blobs;
        setRemainingFrames(Object.keys(framesCache.current).length);
        updateLoadedPercentage();
        if (isDoneUploadingInterval.current === null) {
            isDoneUploadingInterval.current = setInterval(() => {
                if (step === STEP_DONE) {
                    clearInterval(isDoneUploadingInterval.current);
                } else {
                    fetch(`https://aiv2.gcp.paraspot.ai/scan/isDoneUploading?sid=${sid}&frames_sent=${totalFrames-corrupted_blobs}`)
                    .then(response => response.json())
                    .then(response => {
                        if (response.status === 200 && response.result === true) {
                            if (response.last_frame_index == null) recallFinalizeScan(sid, totalFrames-1);
                            setTimeout(() => {
                                switchStep(STEP_DONE);
                            }, 5000);
                        } else if (response.status === 200 && response.hasOwnProperty('frames_received') && response.frames_received > lp_frameIdx.current) {
                            lp_frameIdx.current = response.frames_received;
                            updateLoadedPercentage();
                            lastReceive.current = new Date();
                            if (response.hasOwnProperty('frames_received_list')) {
                                for (let fr_idx of response.frames_received_list) {
                                    if (framesCache.current.hasOwnProperty(parseInt(fr_idx))) {
                                        delete framesCache.current[parseInt(fr_idx)];
                                        setRemainingFrames(Object.keys(framesCache.current).length);
                                    }
                                }
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            }, 15000);
        }
    };

    const recallFinalizeScan = (sid, totalFrames) => {
        (sockets ? sockets[0] : socket).emit('finalize_scan', sid, totalFrames);
    };

    const updateLoadedPercentage = () => {
        if (step === STEP_DONE) return;
        let lp = Math.floor((lp_frameIdx.current+1+framesSent.current-ignoredFrames.current)/(scannedFrames.current*2)*100);
        console.log(`Loaded: ${lp}`);
        setLoadedPercentage(lp);
        if (lp === 100) {
            setNotifState({"type": "success", "msg": "Scan upload will finish in a few moments"});
            setTimeout(() => { 
                if (step !== STEP_DONE) {
                    switchStep(STEP_DONE);
                }
            }, 25000);
        }
        // if (lp === 100) switchStep(STEP_DONE);
    };

    const showError = (errMsg=true) => {
        setShowErrorScreen(errMsg);
    };

    const onSocketConnect = (sock_ref) => {
        setSockConnStatusState((o) => {
            return {
                ...o,
                [sock_ref]: true
            };
        });
    };
    
    const onSocketDisconnect = (sock_ref) => {
        setSockConnStatusState((o) => {
            return {
                ...o,
                [sock_ref]: false
            };
        });
        // const onSocketDisconnect = () => {
        if (step >= STEP_SCAN) {
            midScanDisconnection.current = true;
            socketDisconnections.current += 1;
        }
        if (sock_ref === 0) {
            lastDisconnect.current = new Date();
            if ((new Date()) - lastDisconnect.current > 8000) {
                disconnectCounter.current = 1;
            } else disconnectCounter.current += 1;

            if (disconnectCounter.current > 6) {
                console.log("Re-Establishing connection with socket");
                console.log("sess:", scanSess.current);
                console.log("scannedFrames:", scannedFrames.current);
                sockets.map((x) => {
                    x.close();
                    return null;
                });
                console.log("Initiating the multi sockets connection");
                VideoStreamSocket.initMultiSockets(SOCKET_NS, props.para_be)
                .then((out) => {
                    console.log("Setting sockets:", out);
                    setSockets(out);
                });
                sockConnReset.current += 1;

                // finalizeScan(scanSess.current, scannedFrames.current);
            }
        }
    };

    const onFrameReceived = (frame_idx) => {
        lp_frameIdx.current++;
        delete framesCache.current[frame_idx];
        setRemainingFrames(Object.keys(framesCache.current).length);
        updateLoadedPercentage();
        lastReceive.current = new Date();
    };

    const onFramesResendReq = (sid, framesToResend, apiCall=false) => {
        if (sockets) {
            let i = 0;
            for (let frame_idx of framesToResend) {
                if (apiCall) {
                    console.log(`[${frame_idx}] Sending over API:\n`, framesCache.current[frame_idx]);
                    let d = new FormData();
                    d.append("sid", sid);
                    d.append("frame_idx", parseInt(frame_idx));
                    d.append("frame_data", framesCache.current[frame_idx]);
                    fetch(
                        "https://aiv2.gcp.paraspot.ai/scan/frame_processor", 
                        {
                            method: 'POST',
                            headers: {'withCredentials': true},
                            body: d,
                            credentials: 'include'
                        }
                    )
                    .then(response => {
                        if (response.status === 500) {
                            setFailedApiCalls(failedApiCalls+1);
                        }
                    })
                    .catch(err => {
                        console.log("Failed to send to frame_processor");
                        console.log(err);
                    });
                } else {
                    sockets[i].emit('frame_processor', sid, framesCache.current[frame_idx], parseInt(frame_idx));
                    if (sockets.length-1 === i) {
                        i = 0;
                    } else i++;
                }
            }
        } else {
            for (let frame_idx of framesToResend) {
                // try {
                socket.emit('frame_processor', sid, framesCache.current[frame_idx], parseInt(frame_idx));
                // } catch (e) {
                //     console.log(e)
                //     console.log("[!!onFramesResendReq]", sid, "${", frame_idx, "} =>", framesCache.current[frame_idx]);
                // }
            }
        }
    }

    const onMassFramesResendReq = (sid, framesToResend) => {
        console.log(`[MASS] Sending over API:\n`, framesToResend);
        let framesOut = framesToResend.map( (frame_idx) => parseInt(frame_idx) )
        let d = new FormData();
        d.append("sid", sid);
        d.append("frame_idxs", framesOut);
        for (let i=0; i<framesOut.length; i++) {
            d.append(`frame_data${i}`, framesCache.current[framesOut[i]]);
        }
        fetch(
            "https://aiv2.gcp.paraspot.ai/scan/mass_frame_processor", 
            {
                method: 'POST',
                headers: {'withCredentials': true},
                body: d,
                credentials: 'include'
            }
        )
        .catch(err => {
            console.log("Failed to send to frame_processor");
            console.log(err);
        });
    }

    const uploadRecording = (sid) => {
        if (uploadStatus === 'done') {
            console.log("Upload already done. Skipping...");
            return;
        } else if (uploadStatus === 'ongoing') {
            console.log("Upload is ongoing. Skipping...");
            return;
        }
        const lastStatus = uploadStatus ? (uploadStatus + "") : null;
        setUploadStatus('ongoing');
        let timeNow = new Date();
        timeNow.setMinutes(timeNow.getMinutes() + 7);
        setFileUploadTimer(timeNow);
        setTimeout(() => {
            if (step === STEP_LOADING_ISSUE || step === STEP_RETRY_FILE_UPLOAD) {
                switchStep(STEP_UPLOAD_FAILED);
            }
        }, 1000*60*7);

        const fileToUpload = blobToFile(recordedBlob, sid);
        fetchPresignedUrls(props.para_be, fileToUpload, pid)
        .then(({ upload_id, presigned_urls }) => {
            uploadFile(
                props.para_be, fileToUpload, upload_id, presigned_urls, pid, 
                (uploadRate) => {
                    setRecordingUploadRate(uploadRate);
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_LOADING_ISSUE_RESOLVED);
                }, 
                setUploadLoaderStatus, setNotifState, 
                () => {
                    setUploadStatus('done');
                    switchStep(STEP_DONE);
                }, 
                () => {
                    setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
                },
                () => {
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_RETRY_FILE_UPLOAD);
                }
            );
        })
        .catch((error) => {
            console.error("An error occurred while uploading recorded scan:", error);
            setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
        });
    }

    const finalizeScan = (sid, intervalCount) => {
        // console.log("At finalizeScan in baseline_scan.js");
        setTimeout(
            () => {
                // console.log("Inside the timeout");
                lastResend.current = new Date();
                // lastReceive.current = new Date();
                let intervalIdx = 0;
                finalizeBsInterval.current = setInterval(() => {
                    if (lp_frameIdx.current === intervalCount) {
                        if (sockets) {
                            sockets[0].emit('finalize_scan', sid, intervalCount-1);
                        } else socket.emit('finalize_scan', sid, intervalCount-1);
                        let cachedFrameIdxs = Object.keys(framesCache.current);
                        // console.log("Clearing finalization interval");
                        clearInterval(finalizeBsInterval.current);
                        if (cachedFrameIdxs.length > 0) {
                            // console.log("[END] Resending frames:", cachedFrameIdxs);
                            onFramesResendReq(sid, cachedFrameIdxs);
                        }
                    } else {
                        let cachedFrameIdxs = Object.keys(framesCache.current);
                        if (cachedFrameIdxs.length > 0) {
                            console.log("Time since last received:", new Date() - lastReceive.current);
                            console.log("Is lastMass not null:", lastMass !== null);
                            // if (failedApiCalls > 0) {
                            if (new Date() - lastReceive.current > 20000) {
                                // Uploading the recorded scan
                                uploadRecording(sid);
                                switchStep(STEP_LOADING_ISSUE);
                                clearInterval(finalizeBsInterval.current);
                                return;
                            }
                            if (sockConnReset.current > 0 || lastMass !== null || (lastMass === null && new Date() - lastReceive.current > 10000)) {
                                if (lastMass === null || new Date() - lastMass > 3000) {
                                    const intervalJumps = 5;
                                    onMassFramesResendReq(
                                        sid, 
                                        cachedFrameIdxs.slice(intervalIdx*intervalJumps, (intervalIdx*intervalJumps)+intervalJumps)
                                    )
                                    intervalIdx++;
                                    if (intervalIdx*intervalJumps >= cachedFrameIdxs.length) {
                                        intervalIdx = 0;
                                    }
                                    setLastMass(new Date());
                                }
                            } else {
                                onFramesResendReq(
                                    sid,
                                    (sockConnReset.current > 0 || new Date() - lastReceive.current > 50000) ? (
                                        (isIOS()) ? 
                                            cachedFrameIdxs.slice(intervalIdx*5, (intervalIdx*5)+5) : 
                                            cachedFrameIdxs.slice(intervalIdx*20, (intervalIdx*20)+20)) :    
                                    cachedFrameIdxs.length > 500 ? cachedFrameIdxs.slice(0, parseInt(cachedFrameIdxs.length / 10)) : cachedFrameIdxs,
                                    false
                                );
                                intervalIdx++;
                                if (intervalIdx*5 >= cachedFrameIdxs.length) {
                                    intervalIdx = 0;
                                }
                            }
                        }
                    }
                }, 1000);
            }, 1000
        )
    };

    useEffect(() => {
        fetch(props.para_be + '/scan/authenticateUnit', postReqOptBuilder({'pid': pid, 'cid': cid}))
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if(response.status === 200) {
                    console.log("Can proceed");
                    setShouldCompress(response.result.shouldCompress);
                } else {
                    //TODO - set error
                    console.log("Can't proceed");
                    console.log(response);
                    setStep(-5);
                }
                setLoadingPage(false);
            })
            .catch ( error => { //TODO - add error handling})
            });

        console.log("[i] Init socket");
        // setSocket(VideoStreamSocket.initSocket(SOCKET_NS));
        VideoStreamSocket.initMultiSockets(SOCKET_NS, props.para_be)
        .then((out) => {
            console.log("Setting sockets:", out);
            setSockets(out);
        });
        window.onbeforeunload = function() {
            return "Data will be lost if you leave the page. Are you sure?";
        };        
    }, []);

    useEffect(() => {
        VideoStreamSocket.initSocketEventListeners(socket, null, true, true, true, false, false, true, null, onSocketDisconnect, onFrameReceived, null, null, onFramesResendReq);
        // VideoStreamSocket.initSocketEventListeners(socket, true, true, true, false, false, true, null, onSocketDisconnect, onFrameReceived, null, null, onFramesResendReq);

        return () => {
            if (socket) {
                console.log("[i] Closing socket...");
                socket.close();
            }
        }
    }, [socket]);

    useEffect(() => {
        console.log("Sockets:\n", sockets);
        if (sockets) {
            // for (let s of sockets) {
            for (let s_idx=0; s_idx<sockets.length; s_idx++) {
                let s = sockets[s_idx];
                console.log("Init socket even listeners");
                VideoStreamSocket.initSocketEventListeners(s, s_idx, true, true, true, false, false, true, onSocketConnect, onSocketDisconnect, onFrameReceived, null, null, onFramesResendReq);
                // VideoStreamSocket.initSocketEventListeners(s, true, true, true, false, false, true, null, onSocketDisconnect, onFrameReceived, null, null, onFramesResendReq);
            }
        }

        return () => {
            if (sockets) {
                console.log("[i] Closing socket[s]...");
                for (let s of sockets) {
                    s.close();
                }
            }
        }
    }, [sockets]);

    useEffect(() => {
        if (videoDeviceDets === false) {
            setShowErrorScreen("Cannot perform scan on this device. There is no back facing camera available.");
        }
    }, [videoDeviceDets]);

    useEffect(() => {
        if (uploadStatus === "fail") {
            setNotifState({"type": "error", "msg": "Trying to work through your network issues."});
            uploadRecording(scanSess.current);
        }
    }, [uploadStatus]);

    return (
        <section className={`scan-main${step === STEP_DONE ? " results-mode" : ""}`}>
            <Helmet>
                <title>Create Your Baseline Scan | Paraspot</title>
                <meta name="description" content="Experience Paraspot's leading AI scan from your mobile device."/>
                <meta property="og:title" content="Create Your Baseline Scan | Paraspot"/>
                <meta property="og:description" content="Experience Paraspot's leading AI scan from your mobile device."/>
            </Helmet>

            {debugMode &&
                <div className="debug-layer">
                    <div className="sockets-view">
                        <div className="h3">Sockets</div>
                        {
                            Object.keys(sockConnStatusState).map((k) => {
                                return (
                                    <div className="flexRow text-2">
                                        <div>Sock {k}:</div>
                                        <div>{sockConnStatusState[k] ? "Connected" : "Disconnected"}</div>
                                    </div>
                                );
                            })
                        }
                        <p className="text-3">
                            Last Received: {lastReceive.current ? Math.round((new Date() - lastReceive.current)/1000) : "?"}/s
                            <br/>
                            Remaining Frames: {framesCache.current ? remainingFrames : "N/A"}
                            <br/>
                            Last Mass Resend: {lastMass ? Math.round((new Date() - lastMass)/1000) : "?"}/s
                        </p>
                    </div>
                </div>
            }

            {
                loadingPage ?
                    <MainLoader/> :
                showErrorScreen !== false ?
                    <ScannerError errMsg={showErrorScreen === true ? undefined : showErrorScreen}/> :
                step === -5 ?
                    <div>Error!<br/>Invalid unit ID</div> :
                step === STEP_LOBBY ?
                    <Lobby
                        includeClientLogo={false}
                        onNext={() => switchStep(STEP_EXP)}
                        btnText="Next"
                    /> :
                step === STEP_EXP && videoDeviceDets === null ?
                    <CamSettingsLoader onFinishedLoading={setVideoDeviceDets} /> :
                step === STEP_EXP ?
                    <ScanStep
                        img={step1x1}
                        imgx2={step1x2}
                        imgx3={step1x3}
                        text={
                            <p>
                                <b>Before you start</b><br/>
                                1. Hold your phone Vertically.<br/>
                                2. Make sure the room is well lit.<br/>
                                3. Start from the entrance.<br/>
                            </p>
                        }
                        btnText={"Next"}
                        onSkip={null}
                        onBtnClick={() => switchStep(STEP_EXP2)}
                        step={1}
                        totalSteps={4}
                    /> :
                step === STEP_EXP2 ?
                    <ScanStep
                        img={step1x1}
                        imgx2={step1x2}
                        imgx3={step1x3}
                        text={
                            <p>
                                <b>How To</b><br/>
                                1. Make sure that you completely capture the inventory.<br/>
                                2. Move simply for the convenience of the tenant.<br/>
                                3. Move steady at a normal pace.
                            </p>
                        }
                        btnText={"Let's Start"}
                        onSkip={null}
                        onBtnClick={() => switchStep(STEP_SCAN)}
                        step={2}
                        totalSteps={4}
                    /> :
                step === STEP_SCAN ?
                    (sockets && <VideoCamera
                        // socket={socket}
                        sockets={sockets}
                        FPS={FPS}
                        cameraConstraints={getFinalConstraints()}
                        switchToLoading={switchToLoading}
                        finalizationCallback={finalizeScan}
                        showError={showError}
                        endUserInfo={{'uid': uid, 'cid': cid, 'pid': pid, 'token': token}}
                        camType={CAM_TYPE_BASELINE}
                        showTimeStamp={true}
                        framesCache={framesCache}
                        compressSize={shouldCompress ? 640 : undefined}
                        setRecordedBlob={setRecordedBlob}
                        para_be={props.para_be}
                        transmitSid={(sid) => { scanSess.current = sid; }}
                    />) :
                (step === STEP_DONE && 
                    <SimpleLoader 
                        loaderSuccess={true} 
                        msg={
                            <p><b>Well Done!</b><br/>You cleared the scan</p>
                        } 
                        successCallback={ () => {window.location.href=base_url + "/management"} }
                        {...(recordedBlob ?
                            {
                                extraElementEnd: <>
                                    <a className="simple-btn btn-secondary" target="_blank" style={{marginTop: '20px'}} download={`${scanSess.current}.${getFileExtFromBlob(recordedBlob)}`} href={URL.createObjectURL(recordedBlob)}>Download Scan</a>
                                    {debugMode &&
                                        <>
                                            <Btn type="secondary" text="Upload Scan" style={{marginTop: '10px', marginBottom: '10px'}} onclick={() => uploadRecording(scanSess.current)} />
                                            <div className="text-2">Upload Status: {recordingUploadRate}/100 | {uploadStatus}</div>
                                        </>
                                    }
                                </>
                            } : {}
                        )}
                    />
                )
            }
            {step !== STEP_DONE && finalizingScan ? 
                <SimpleLoader 
                    loadedPercentage={
                        (step === STEP_UPLOAD_FAILED ? 
                            null :
                            (step === STEP_LOADING_ISSUE_RESOLVED || step === STEP_RETRY_FILE_UPLOAD) ? 
                                (loadedPercentage > recordingUploadRate ? 
                                    loadedPercentage : 
                                    recordingUploadRate
                                ) : loadedPercentage
                        )
                    } 
                    msg={
                        step === STEP_LOADING_ISSUE ?
                            <>
                                Something Went Wrong<br/>
                                <div className="text-0">Do not leave the screen.</div>
                                {fileUploadTimer &&
                                    <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                }
                                <br/>
                                <div className="text-3">
                                    We noticed an issue with your network connection.<br/>
                                    Please hang tight while we try to resolve it.
                                </div>
                            </> :
                            (step === STEP_RETRY_FILE_UPLOAD ?
                                <>
                                    Loading<br/>
                                    <div className="text-0">Do not leave the screen</div>
                                    {fileUploadTimer &&
                                        <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                    }
                                    <br/>
                                    <div className="text-3">
                                        The network issue persists.<br/>
                                        Please hold on for a bit longer as we are trying to establish connection.
                                    </div>
                                </> :
                                (step === STEP_LOADING_ISSUE_RESOLVED ?
                                    <>
                                        Loading<br/>
                                        <div className="text-0">Do not leave the screen</div>
                                        {fileUploadTimer &&
                                            <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                        }
                                        <br/>
                                        <div className="text-3">Issue resolved! You should be on  your way in just a bit. Thank you for your patience!</div>
                                    </> : 
                                    (step === STEP_UPLOAD_FAILED ?
                                        <>
                                            Upload Failed<br/>
                                            <div className="text-0">Network Connectivity Issue</div><br/>
                                            <div className="text-3">
                                                Your network connection is unavailable or unstable.<br/>
                                                We appreciate your patience as we tried to mediate the issue on your device.<br/>
                                                Please download the scan, by clicking on the Download Scan button below, and upload it once you are connected to a stable network.<br/>
                                                You may also retry to upload the scan by clicking on the Retry button below.<br/><br/>
                                                Thank you for your cooperation!
                                            </div>
                                        </> :
                                        <>
                                            Loading<br/>
                                            <div className="text-0">Do not leave the screen.</div>
                                            {fileUploadTimer &&
                                                <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                            }
                                            <br/>
                                            <div className="text-3">If your network connection is slow or unstable, you may experience longer upload times. We appreciate your patience!</div>
                                        </>
                                    )
                                )
                            )
                    }
                    remainingData={remainingFrames}
                    {...(recordedBlob ?
                            {
                                extraElementEnd: <>
                                    <a className="simple-btn btn-secondary" target="_blank" style={{marginTop: '20px', marginBottom: '10px'}} download={`${scanSess.current}.${getFileExtFromBlob(recordedBlob)}`} href={URL.createObjectURL(recordedBlob)}>Download Scan</a>
                                    {step === STEP_UPLOAD_FAILED &&
                                        <Btn 
                                            type="secondary" 
                                            text="Retry Upload" 
                                            style={{marginTop: '10px', marginBottom: '10px'}} 
                                            onclick={() => {
                                                switchStep(STEP_RETRY_FILE_UPLOAD);
                                                uploadRecording(scanSess.current);
                                            }} 
                                        />
                                    }
                                    {debugMode &&
                                        <>
                                            <Btn type="secondary" text="Upload Scan" style={{marginTop: '10px', marginBottom: '10px'}} onclick={() => uploadRecording(scanSess.current)} />
                                            <div className="text-2">Upload Status: {recordingUploadRate}/100 | {uploadStatus}</div>
                                        </>
                                    }
                                </>
                            } : {}
                    )}
                /> : ""}
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.msg}
                    type={notifState.type}/> : ""
            }
        </section>
    );
}