import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
//components
import MasterPopup from "../../components/popups/main_popup";
import Notification from "../../components/side_notification/side_notification";
//utils
//styles
import "../review_page/review_page.css";
import "../../components/cards/report_card/report_card.css";
//assets

export default function InventoryReview(props) {

    const pid = props.match.params?.pid;
    const src_id = props.match.params?.src_id;
    const scanType = (new URLSearchParams(window.location.search))?.get('type');
    const [reportData, setReportData] = useState(null); //structure: [subject, report_date, [overview issues], [major_issues], media_src]
    const [presentImView, setPresentImView] = useState(null);
    const [selectedImgViews, setSelectedImgViews] = useState(null);
    const [notifState, setNotifState] = useState(null);
    
    useEffect(()=> {
        (pid ?
            fetch(props.para_be + '/units/list_unit_inventory?pid=' + pid) :
            fetch(props.para_be + '/scan/get_scan_detections?src=' + src_id + "&type=" + scanType)
        )
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                setReportData(response.result);
            } else {
                setNotifState({text: "Failed to load admin report data", type: "error"});
            }
        })
        .catch ( error => {
            setNotifState({text: "Failed to load admin report data", type: "error"});
        });
    // eslint-disable-next-line no-sequences
    }, []);

    useEffect(() => {
        if (presentImView !== null) {
            fetch(presentImView.replace("getObjectFromScan", "getObjectViewsFromScan"))
            .then(response => response.json())
            .then(response => {
                setSelectedImgViews(response.result);
            });
        } else setSelectedImgViews(null);
    }, [presentImView]);

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const onObjImgLoaded = (e) => {
        let im = e.target;
        if (im.naturalHeight === 0) {
            console.log("Image was not loaded successfully.\nReloading image...");
            let reloadedIm = im.getAttribute("para_reloadCounter");
            reloadedIm = reloadedIm === null ? 0 : parseInt(reloadedIm);
            if (reloadedIm === 5) {
                return;
            }
            // eslint-disable-next-line no-self-assign
            im.src = im.src;
            im.setAttribute("para_reloadCounter", reloadedIm  + 1);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Paraspot | Property Inventory - {reportData ? reportData[1] : ""}</title>
                <meta name="description" content={`Paraspot inventory view of the unit ${reportData ? reportData[1] : ""}`}/>
                <meta property="og:title" content={`Paraspot | Property Inventory - ${reportData ? reportData[1] : ""}`}/>
                <meta property="og:description" content={`Paraspot inventory view of the unit ${reportData ? reportData[1] : ""}`}/>
            </Helmet>
            {presentImView &&
                <MasterPopup extraClasses="non-padded-popup image-presentation-popup" closeFunc={() => { console.log("Closing popup..."); setPresentImView(null); }}>
                    <>
                        {presentImView.includes("/cva/v2/getObjectFromScan") && 
                            <div className="images-gallery">
                                {
                                    [...Array(selectedImgViews ? selectedImgViews : 0).keys()].map((i) => {
                                        return <img alt="issue-pic-item" src={presentImView + "&view_idx=" + i + "&zoom_out=true"} onClick={() => document.querySelector(".image-presentation > img").src = presentImView + "&view_idx=" + i + "&zoom_out=true"}/>
                                    })
                                }
                            </div>
                        }
                        <div className="image-presentation">
                            <img alt="issue-pic" src={presentImView + "&zoom_out=true"}/>
                        </div>
                    </>
                </MasterPopup>
            }
            <section>
                <article className="ov-header">
                    <div>
                        <div className="h2 blue-headline">{reportData ? reportData[1] : ""}</div>
                    </div>
                </article>

                { reportData &&
                    <article className="ov-body">
                        <div className="flexRow" style={{flexWrap: "wrap"}}>
                            {Object.entries(reportData[2]).map(([uid, item]) => {
                                let imgLink = `https://aiv2.gcp.paraspot.ai/cva/v2/getObjectFromScan?src_id=${scanType && scanType !== 'baseline' ? src_id : reportData[0]}&from=${scanType && scanType !== 'baseline' ? 'tenant' : 'prev'}&uid=${uid}&obj_box=[${item['bbox'][0]}]`;
                                return <>
                                    <section className="card-body flexColumn" id={"det-" + uid}>
                                        <div className="bd-data-container flexRow">
                                            <img src={imgLink} alt="" onLoad={onObjImgLoaded} onError={onObjImgLoaded} onClick={() => { setPresentImView(imgLink); }}/>
                                            <div className="flexColumn">
                                                <div className="text-1-3">{`${item['label'].replace("m4_", "").replace("ade_", "")} (${uid})`}</div>
                                                <div className="text-1"></div>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            })}
                        </div>
                    </article>
                }

                {notifState ?
                    <Notification
                        closeFunc={handleCloseNotif}
                        text={notifState.text}
                        type={notifState.type}/> : ""
                }
            </section>
        </div>
    )
}