import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
//components
import Btn from "../../components/buttons/standard/btn";
import MasterPopup from "../../components/popups/main_popup";
import Notification from "../../components/side_notification/side_notification";
//utils
import { postReqOptBuilder } from "../../utils/main_utils";
//styles
import "../review_page/review_page.css";
import "../../components/cards/report_card/report_card.css";
//assets

export default function ScanManagement(props) {

    const reportId = props.match.params.rid;
    const [reportData, setReportData] = useState(null); //structure: [subject, report_date, [overview issues], [major_issues], media_src]
    const [presentImView, setPresentImView] = useState(null);
    const [selectedImgViews, setSelectedImgViews] = useState(null);
    const [notifState, setNotifState] = useState(null);
    
    useEffect(()=> {
        fetch(props.para_be + '/reports/get_admin_report_data?report_id=' + reportId)
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                setReportData(response.result);
            } else {
                setNotifState({text: "Failed to load admin report data", type: "error"});
            }
        })
        .catch ( error => {
            setNotifState({text: "Failed to load admin report data", type: "error"});
        });
    // eslint-disable-next-line no-sequences
    }, []);

    useEffect(() => {
        if (presentImView !== null) {
            fetch(presentImView.replace("getObjectFromScan", "getObjectViewsFromScan"))
            .then(response => response.json())
            .then(response => {
                setSelectedImgViews(response.result);
            });
        } else setSelectedImgViews(null);
    }, [presentImView]);

    const add_X_remove_ItemToReport = (objID, additionType, add_X_remove) => {
        let itemData = additionType === "defect" ? reportData[3][objID] : reportData[2][objID]
        itemData['label'] = itemData['label'].replace("m4_", "").replace("ade_", "")
        fetch(props.para_be + (add_X_remove ? "/reports/add_to_report" : "/reports/remove_from_report"), postReqOptBuilder({report_id: reportId, additionType: additionType, itemID: objID, itemData: itemData}))
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                setNotifState({text: `Item (${objID}) was successfully ${add_X_remove ? 'added' : 'removed'} to ${additionType} items`, type: "success"})
            } else {
                setNotifState({text: `Failed to ${add_X_remove ? 'add' : 'remove'} item (${objID}) to ${additionType} items`, type: "error"})
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: `An error arose while trying to ${add_X_remove ? 'add' : 'remove'} item (${objID}) to ${additionType} items`, type: "error"})
        })
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const onObjImgLoaded = (e) => {
        let im = e.target;
        if (im.naturalHeight === 0) {
            console.log("Image was not loaded successfully.\nReloading image...");
            let reloadedIm = im.getAttribute("para_reloadCounter");
            reloadedIm = reloadedIm === null ? 0 : parseInt(reloadedIm);
            if (reloadedIm === 5) {
                return;
            }
            // eslint-disable-next-line no-self-assign
            im.src = im.src;
            im.setAttribute("para_reloadCounter", reloadedIm  + 1);
        }
    };

    return (
        <div>
            <Helmet>
                <title>[ADMIN] Paraspot | Report - {reportData ? reportData[1] : ""}</title>
                <meta name="description" content={`Admin Report of the unit ${reportData ? reportData[1] : ""}`}/>
                <meta property="og:title" content={`[ADMIN] Paraspot | Report - ${reportData ? reportData[1] : ""}`}/>
                <meta property="og:description" content={`Admin Report of the unit ${reportData ? reportData[1] : ""}`}/>
            </Helmet>
            {presentImView &&
                <MasterPopup extraClasses="non-padded-popup image-presentation-popup" closeFunc={() => { console.log("Closing popup..."); setPresentImView(null); }}>
                    <>
                        {presentImView.includes("/cva/v2/getObjectFromScan") && 
                            <div className="images-gallery">
                                {
                                    [...Array(selectedImgViews ? selectedImgViews : 0).keys()].map((i) => {
                                        return <img alt="issue-pic-item" src={presentImView + "&view_idx=" + i + "&zoom_out=true"} onClick={() => document.querySelector(".image-presentation > img").src = presentImView + "&view_idx=" + i + "&zoom_out=true"}/>
                                    })
                                }
                            </div>
                        }
                        <div className="image-presentation">
                            <img alt="issue-pic" src={presentImView + "&zoom_out=true"}/>
                        </div>
                    </>
                </MasterPopup>
            }
            <section>
                <article className="ov-header">
                    <div>
                        <div className="h2 blue-headline">{reportData ? reportData[1] : ""}</div>
                    </div>
                </article>

                {/* https://aiv2.paraspot.ai/cva/getObjectFromScan?pid={res[0]}&uid={k}&obj_box= */}
                { reportData &&
                    <article className="ov-body">
                        <div className="flexRow" style={{flexWrap: "wrap"}}>
                            {Object.entries(reportData[2]).map(([uid, item]) => {
                                let imgLink = `https://aiv2.gcp.paraspot.ai/cva/getObjectFromScan?pid=${reportData[0]}&uid=${uid}&obj_box=[${item['bbox'][0]}]`;
                                return <>
                                    <section className="card-body flexColumn" id={"det-" + uid}>
                                        <div className="bd-data-container flexRow">
                                            <img src={imgLink} alt="" onLoad={onObjImgLoaded} onError={onObjImgLoaded} onClick={() => { setPresentImView(imgLink); }}/>
                                            <div className="flexColumn">
                                                <div className="text-1-3">{`${item['label']} (${uid})`}</div>
                                                <div className="text-1"></div>
                                            </div>
                                        </div>
                                        <div className="flexRow buttons-wrapper">
                                            <Btn
                                                text="Add to missing from base"
                                                type="primary"
                                                onclick={() => add_X_remove_ItemToReport(uid, "missing", true)}
                                                />
                                            <Btn
                                                text="Remove from missing from base"
                                                type="primary"
                                                onclick={() => add_X_remove_ItemToReport(uid, "missing", false)}
                                                />
                                            <Btn
                                                text="Add to misplaced"
                                                type="primary"
                                                onclick={() => add_X_remove_ItemToReport(uid, "misplaced", true)}
                                                />
                                            <Btn
                                                text="Remove from misplaced"
                                                type="primary"
                                                onclick={() => add_X_remove_ItemToReport(uid, "misplaced", false)}
                                                />
                                            </div>
                                    </section>
                                </>
                            })}
                            {Object.entries(reportData[3]).map(([uid, item]) => {
                                let imgLink = `https://aiv2.gcp.paraspot.ai/cva/v2/getObjectFromScan?src_id=${reportId}&from=tenant&uid=${uid}`;
                                return <>
                                    <section className="card-body flexColumn" id={"det-" + uid}>
                                        <div className="bd-data-container flexRow">
                                            <img src={imgLink} alt="" onLoad={onObjImgLoaded} onError={onObjImgLoaded} onClick={() => { setPresentImView(imgLink); }}/>
                                            <div className="flexColumn">
                                                <div className="text-1-3">{`${item['label']} (${uid})`}</div>
                                                <div className="text-1"></div>
                                            </div>
                                        </div>
                                        <div className="flexRow buttons-wrapper">
                                            <Btn
                                                text="Add to defects from report"
                                                type="primary"
                                                onclick={() => add_X_remove_ItemToReport(uid, "defect", true)}
                                                />
                                            <Btn
                                                text="Remove from defects from report"
                                                type="primary"
                                                onclick={() => add_X_remove_ItemToReport(uid, "defect", false)}
                                                />
                                            </div>
                                    </section>
                                </>
                            })}
                        </div>
                    </article>
                }

                {notifState ?
                    <Notification
                        closeFunc={handleCloseNotif}
                        text={notifState.text}
                        type={notifState.type}/> : ""
                }
            </section>
        </div>
    )
}